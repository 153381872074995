<template>
  <y-layout-body-menu aside-width="9rem" :menu="[
    {title:'空码管理',path:'/card/plugin/cdkey/empty'},
    {title:'用户管理',path: '/card/plugin/cdkey/user'},
    {title:'全部激活码',path: '/card/plugin/cdkey/list'},
  ]">
    <router-view/>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu}
}
</script>

<style scoped>

</style>